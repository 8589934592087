import styled from "styled-components";
import { theme } from "../GlobalStyles";
import { device } from "../constants/device";
import Button from "../components/Button";


const Wrapper = styled.div`
    margin: 2em 0;
    pre {
        padding: 2em;
        text-align: justify;
        overflow: scroll;
        border-left: 5px solid #4d4d4d;
        background-color: ${theme.cardhover};
    }

    code {
        overflow: scroll;
    }
    overflow: scroll;
`;



const FormattedMessage = ({content}: {content: object}) => {
    const message: string = JSON.stringify(content, undefined, 4)
    return (
        (message !== `""`) ? 
        <Wrapper>
            <pre>
                <code>
                    {message}
                </code>
            </pre>
            <div className="selection">
                <Button transparent onClick={() => {navigator.clipboard.writeText(message)}}>Copy Text</Button>
                <Button >Download JSON</Button>
            </div>
        </Wrapper> : null
    )
}

export default FormattedMessage
