import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Container from './layout/Container';
import Header from './layout/Header';
import Footer from './layout/Footer';
import { device } from './constants/device';
import { Textarea } from './components/Forms';
import Results from './views/Results';
import Formatted from './views/Formatted';
import Parsed from './views/Parsed'
import Button from './components/Button';
import { sample } from './sample';

const Wrapper = styled.div`
	select {
		background-color: gray;
		border: none;
		border-radius: 0.5em;
		padding: 0.25em;
	}
	.view {
		display: grid;
		grid-template-columns: 1fr;
		gap: 2em;
		@media ${device.tablet} {
			grid-template-columns: repeat(2, 1fr);
		}
	}
	
`;

function App() {

	interface Message {
		message: any,
		setMessage: any
	}


	const [input, setInput] = useState('')

	const zz: any = {
		"formatted": "",
		"parsed": "",
		"results": ""
	}

    const [message, setMessage] = useState<any>(zz)

	const loadSample = () => {
        setInput(sample)
    }

    const clearInput = () => {
        setInput('')
        setMessage(zz)
    }

	console.log(message)

	const handlesubmit = () => {
		if (input.length < 3) {
			return null
		}
       const machine_string = { "data": input }
		console.log(machine_string)
		// https://wzslbvrigg.execute-api.ap-southeast-1.amazonaws.com/dev
        fetch('https://hl7.seanvelasco.workers.dev', {
            method: 'POST',
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(machine_string)
        })
        .then(response => response.json())
        .then(data => setMessage(data))
    }
	return (
		<>
		<header>
			<Header />
		</header>
		<Wrapper>
			<Container>
				<Textarea value={input} placeholder='Paste raw machine message here' onChange={(event) => setInput(event.target.value)} />
				<div className="selection">
                    <Button onClick={loadSample}  transparent>Load sample</Button>
                    <Button onClick={clearInput}  transparent>Clear</Button>
                	<Button onClick={handlesubmit}>Parse message</Button>
                </div>
				<div className='view'>
					{/* <Parsed content={message['parsed']}/> */}
					<Formatted content={message['raw']} />
				</div> 
				{/* <Results content={message['results']} /> */}
				{/* <Results content={message['results']}/>
				*/}
			</Container>
		</Wrapper>
		<footer>
			<Footer />
		</footer>
		</>
  );
}

export default App;
