import styled from 'styled-components'

const Wrapper = styled.div`
    margin-bottom: 1em;
`;

const Form = styled.input`
    font-size: 1.2em;
    background-color: #4d4d4d;
    border-radius: 0.25em;
    min-height: 40px;
    padding: 0.75em 0.75em;
    display: block;
    width: 100%;
    border: none;
    color: white;
    margin-top: 0.5em;
    overflow: auto;
    box-sizing: border-box;
`;

const Input = styled(Form)`
    max-height: 100px;
`;

const Textarea = styled(Form).attrs({as: "textarea"})`
    min-width: 100%;
    min-height: 400px;
    resize: none;
    margin: 2em 0;
`;

const Field = ({label}: {label: String}) => {
    return (
        <Wrapper>
            <label>{label}</label>
            <Input></Input>
        </Wrapper>
    )
}

export default Field;

export {
    Input,
    Textarea,
};

